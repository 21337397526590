import React, {useEffect, useRef, useState} from 'react';
import bgpattern4 from '../media/NEW/90s_pattern4.png';
import contactmelogo from '../media/NEW/contactmelogo.png'
import bgpattern3 from "../media/NEW/90s_pattern3.png";
import portoLogo from "../media/NEW/PortoShowLogo.png";

import {MdAttachEmail} from 'react-icons/md'
import {RiGithubLine, RiLinkedinBoxFill} from 'react-icons/ri'

function Contact()
{
    return (
    <div>
        <div className='flex flex-col gap-2 bg-slate-900 md:m-20 text-white shadow-2xl p-10 rounded-l-3xl rounded-r-3xl justify-center items-center max-[1199px]:hidden min-[1200px]:flex' id='intro' style={{backgroundImage: `url(${bgpattern4})`}}>
            <div className='flex items-center justify-center '>
                <img src={contactmelogo} alt='SpecialitiesAndSkills' width={'50%'}/>
            </div>
            <div className={'flex flex-col w-full items-center'}>
                <h1 className={'text-3xl text-center'}>
                   Have some questions? Interested anything about my works? Or just curious in general?
                </h1>
                <h1 className={'text-xl text-center py-3'}>
                    Get in touch with me through different contact methods down below to get this train rolling!
                </h1>
            </div>
            <div className="flex flex-row flex-wrap gap-12 m-8 items-center justify-center">
                <div className='flex flex-col border-white border-2 gap-2 bg-[#162745] text-white drop-shadow-lg h-[15rem] w-auto p-6 rounded-2xl it items-center justify-center' >
                    <i><MdAttachEmail size='120' style={{fill: 'white'}}/></i>
                    <h1 className={'text-xl text-center py-1'}>
                        EMAIL
                    </h1>
                    <a href={'mailto:gabrielaudbeltran23@gmail.com'}>gabrielaudbeltran23@gmail.com</a>
                </div>
                <div className='flex flex-col border-white border-2 gap-2 bg-[#162745] text-white drop-shadow-lg h-[15rem] w-auto p-6 rounded-2xl it items-center justify-center' >
                    <i><RiGithubLine size='120' style={{fill: 'white'}}/></i>
                    <h1 className={'text-xl text-center py-1'}>
                        GitHub
                    </h1>
                    <a href={'https://github.com/otakuweebster'}>otakuweebster</a>
                </div>
                <div className='flex flex-col border-white border-2 gap-2 bg-[#162745] text-white drop-shadow-lg h-[15rem] w-auto p-6 rounded-2xl it items-center justify-center' >
                    <i><RiLinkedinBoxFill size='120' style={{fill: 'white'}}/></i>
                    <h1 className={'text-xl text-center py-1'}>
                        LinkedIn
                    </h1>
                    <a href={'https://www.linkedin.com/in/gabriel-audrey-beltran-4006b221b/'}>Gabriel Audrey Beltran</a>
                </div>
            </div>
        </div>

        <div className='flex flex-col gap-2 bg-slate-900 m-5 text-white w-50% drop-shadow-lg p-5 rounded-l-3xl rounded-r-3xl justify-center items-center max-[1199px]:flex min-[1200px]:hidden ' id='intro' style={{backgroundImage: `url(${bgpattern4})`}}>
            <div className='flex items-center justify-center '>
                <img src={contactmelogo} alt='SpecialitiesAndSkills' width={'50%'}/>
            </div>
            <div className={'flex flex-col w-full items-center'}>
                <h1 className={'text-3xl text-center'}>
                    Have some questions? Interested anything about my works? Or just curious in general?
                </h1>
                <h1 className={'text-xl text-center py-3'}>
                    Get in touch with me through different contact methods down below to get this train rolling!
                </h1>
            </div>
            <div className="flex flex-row flex-wrap gap-12 m-8 items-center justify-center">
                <div className='flex flex-col border-white border-2 gap-2 bg-[#162745] text-white drop-shadow-lg h-[15rem] w-auto p-6 rounded-2xl it items-center justify-center' >
                    <i><MdAttachEmail size='120' style={{fill: 'white'}}/></i>
                    <h1 className={'text-xl text-center py-1'}>
                        EMAIL
                    </h1>
                    <a href={'mailto:gabrielaudbeltran23@gmail.com'}>Email me up!</a>
                </div>
                <div className='flex flex-col border-white border-2 gap-2 bg-[#162745] text-white drop-shadow-lg h-[15rem] w-auto p-6 rounded-2xl it items-center justify-center' >
                    <i><RiGithubLine size='120' style={{fill: 'white'}}/></i>
                    <h1 className={'text-xl text-center py-1'}>
                        GitHub
                    </h1>
                    <a href={'https://github.com/otakuweebster'}>otakuweebster</a>
                </div>
                <div className='flex flex-col border-white border-2 gap-2 bg-[#162745] text-white drop-shadow-lg h-[15rem] w-auto p-6 rounded-2xl it items-center justify-center text-center' >
                    <i><RiLinkedinBoxFill size='120' style={{fill: 'white'}}/></i>
                    <h1 className={'text-xl text-center py-1'}>
                        LinkedIn
                    </h1>
                    <a href={'https://www.linkedin.com/in/gabriel-audrey-beltran-4006b221b/'}>Gabriel Audrey Beltran</a>
                </div>
            </div>
        </div>
    </div>
    )

}

export default Contact