import React, {useEffect, useRef, useState} from 'react';
import bgpattern3 from "../media/NEW/90s_pattern3.png";
import portoLogo from "../media/NEW/PortoShowLogo.png";
import {AiFillCode} from "react-icons/ai";
import {FaGlobeAmericas} from "react-icons/fa";
import broadlogo from "../media/NEW/broadwaylogo.png";
import broadbg from "../media/NEW/broadbg.png";
import { IoLogoJavascript, IoLogoHtml5, IoLogoCss3 } from 'react-icons/io'
import { SiJquery, SiAdobephotoshop, SiAdobeillustrator } from 'react-icons/si'
import {FaJava} from 'react-icons/fa'

import cadcammylgo from '../media/NEW/cadcammylogo.png'
import cadcammybg from '../media/NEW/cadcammybg.png'

import thewatchlogo from '../media/NEW/theWatchlogo.png'
import thewatchbg from '../media/NEW/thewatchbg.png'

import hrytsak from '../media/NEW/hrytbg.png'
import hokkaido from '../media/NEW/hokkaidobg.png'
import stjcard from '../media/NEW/stjcardbg.png'
import stjybcard from '../media/NEW/stjybbg.png'

function Portfolio()
{
    const [portfolio, setPortfolio] = useState('PROGRAM');

    return (
        <div>
            <div className='flex flex-col gap-2 bg-slate-900 md:m-20 text-white shadow-2xl p-10 rounded-l-3xl rounded-r-3xl justify-center items-center max-[1199px]:hidden min-[1200px]:flex' id='intro' style={{backgroundImage: `url(${bgpattern3})`}}>
                <div className='flex items-center justify-center '>
                    <img src={portoLogo} alt='SpecialitiesAndSkills' width={'50%'}/>
                </div>

                <div className={'flex flex-col w-full items-center'}>
                    <h1 className={'text-2xl text-center'}>
                        For over three years in the creative and programming industry, I've been creating sophisicated web and general programming applications to the aesthetically pleasing graphic designs that both clients loved. <br></br><br></br> Let's check it out, shall we?
                    </h1>
                </div>

                <div className="flex flex-row flex-wrap gap-1 justify-center">
                    <button className="button button--nina button--text-thick button--text-upper button--size-m"
                            onClick={() => setPortfolio("PROGRAM")}
                            data-text="PROGRAMMING"><span>P</span><span>R</span><span>O</span><span>G</span><span>A</span><span>M</span><span>M</span><span>I</span><span>N</span><span>G</span>
                    </button>
                    <button className="button button--nina button--text-thick button--text-upper button--size-m"
                            onClick={() => setPortfolio("GRAPHIC")}
                            data-text="GRAPHIC ART"><span>G</span><span>R</span><span>A</span><span>P</span><span>H</span><span>I</span><span>C</span><span>&nbsp;</span><span>A</span><span>R</span><span>T</span>
                    </button>
                </div>

                {portfolio === "PROGRAM" &&

                    <div className="flex flex-row flex-wrap gap-12 m-8 items-center justify-center">

                        <div className='flex flex-col gap-2 bg-slate-900 text-white drop-shadow-lg h-[58rem] w-[38rem] p-6 rounded-2xl it items-center justify-center portfolio-item'  style={{backgroundImage: `url(${broadbg})`, backgroundSize: `cover`}}>
                            <img src={broadlogo} alt='WhatISpecializeOn' width='50%'/>
                            <div className={'flex flex-row w-full justify-center gap-2'}>
                                <i><IoLogoJavascript size='35' style={{fill: 'white'}}/></i>
                                <i><IoLogoHtml5 size='35' style={{fill: 'white'}}/></i>
                                <i><IoLogoCss3 size='35' style={{fill: 'white'}}/></i>
                                <i><SiJquery size='35' style={{fill: 'white'}}/></i>
                            </div>
                            <div className={'w-100 p-5'}>
                                The Broadway Roastery Website served as one of our website redesign projects that my group made for the CWEB195 (UI/UX Fundamentals) course at Saskatchewan Polytechnic. <br/><br/>
                                The goal of the project is to heavily redesign one exisiting website and make it more user friendly and appealing to the general public. <br/><br/>
                                Due to the <a href='https://www.broadwayroastery.com/'><u>original website's</u></a> contents were lacking for us to serve as a basis, me and our group decided to remade it from the ground up. <br/><br/>
                                I have worked on the UI design aspect of it, as well as form validation on the contact page of it. It was a fun project, however, considering we learned how coffee business works along the way.
                            </div>
                            <button className="button button--nina button--text-thick button--text-upper button--size-s bruh" onClick={() => {window.location.href = "https://github.com/otakuweebster/BroadwayRoasteryRedesign"}}
                                    data-text=" Broadway Roastery "><span>V</span><span>I</span><span>E</span><span>W</span><span>&nbsp;</span><span>O</span><span>N</span><span>&nbsp;</span><span>G</span><span>I</span><span>T</span><span>H</span><span>U</span><span>B</span>
                            </button>
                        </div>
                        <div className='flex flex-col gap-2 bg-slate-900 text-white drop-shadow-lg h-[58rem] w-[38rem] p-6 rounded-2xl it items-center justify-center portfolio-item'  style={{backgroundImage: `url(${cadcammybg})` , backgroundSize: `cover`}}>
                            <img src={cadcammylgo} alt='WhatISpecializeOn' width='85%'/>
                            <div className={'flex flex-row w-full justify-center gap-2'}>
                                <i><FaJava size='35' style={{fill: 'white'}}/></i>
                            </div>
                            <div className={'w-100 p-5'}>
                                The Invasion of the Evil Mutant Cadcam Aliens is one of the assignments that me and my fellow student, Levi Krozser, has created for the COSC190 (Advanced Programming) at Saskatchewan Polytechnic. <br/><br/>
                                The idea of the entire assignment is to showcase how thread operations and JavaFX (Java's client application) works by incorporating it into a Space Invaders-like game.<br/><br/>
                                Me and my partner worked it in a way that is quite interesting and interactive by incorporating custom-designed characters and assets I have created.<br/><br/>
                                For that reason, I have fond good memories due to how complex thread operations work, in the same recreating the program to have a unique style and design that made it stand out from the rest.
                            </div>
                            <button className="button button--nina button--text-thick button--text-upper button--size-s bruh" onClick={() => {window.location.href = "https://github.com/otakuweebster/cad-cam-invaders"}}
                                    data-text="THE INVASION OF CADCAM ALIENS"><span>V</span><span>I</span><span>E</span><span>W</span><span>&nbsp;</span><span>O</span><span>N</span><span>&nbsp;</span><span>G</span><span>I</span><span>T</span><span>H</span><span>U</span><span>B</span>
                            </button>
                        </div>
                        <div className='flex flex-col gap-2 bg-slate-900 text-white drop-shadow-lg h-[58rem] w-[38rem] p-6 rounded-2xl it items-center justify-center portfolio-item'  style={{backgroundImage: `url(${thewatchbg})` , backgroundSize: `cover`}}>
                            <img src={thewatchlogo} alt='WhatISpecializeOn' width='50%'/>
                            <div className={'flex flex-row w-full justify-center gap-2'}>
                                <i><FaJava size='35' style={{fill: 'white'}}/></i>
                            </div>
                            <div className={'w-100 p-5'}>
                                theWatch is one of my individual final projects for the COSC195 (Android Programming) at Saskatchewan Polytechnic.<br/><br/>
                                The entire goal of the project is to showcase how different mobile operations, database calling, and codes to interact with different modules in Java in a way that we incorporate them in our style.<br/><br/>
                                The idea of theWatch is to act as a "community crime collector/aggregator" to report suspicious criminal activity in their area and alert residents of the neighborhood where the crime has been reported.<br/><br/>
                                In the end, I have created an application, albeit beta-like, that meets those requirements. Basically, it was a fun way how Java and Android Studio cooperates each other to create an app.

                            </div>
                            <button className="button button--nina button--text-thick button--text-upper button--size-s bruh" onClick={() => {window.location.href = "https://github.com/otakuweebster/The-Watch"}}
                                    data-text="theWatch"><span>V</span><span>I</span><span>E</span><span>W</span><span>&nbsp;</span><span>O</span><span>N</span><span>&nbsp;</span><span>G</span><span>I</span><span>T</span><span>H</span><span>U</span><span>B</span>
                            </button>
                        </div>
                    </div>

                }

                {portfolio === "GRAPHIC" &&

                    <div className="flex flex-row flex-wrap gap-12 m-8 items-center justify-center text-center">

                        <div className='flex flex-col gap-2 bg-slate-900 text-white drop-shadow-lg h-[35rem] w-[38rem]  p-6 rounded-2xl it items-center justify-center portfolio-item'  style={{backgroundImage: `url(${hrytsak})` , backgroundSize: `cover`}}>
                            <div className={'flex flex-row w-full justify-center gap-2'}>
                                <i><SiAdobephotoshop size='35' style={{fill: 'white'}}/></i>
                            </div>
                            <div className={'w-100 p-5'}>
                               Designed a woodgraining logo for a client to emphasizes on woodcrafting and little canadian elements on it. Using the client's old logo as a basis, I made improvements with it by incorporating multiple woodcrafting elements with a little hint of the client's idea and Canadian elements in it.
                            </div>
                            <button className="button button--nina button--text-thick button--text-upper button--size-s bruh" onClick={() => {window.location.href = "https://imgbox.com/zjvCbmFE"}}
                                    data-text=" D.S. HRYTSAK "><span>V</span><span>I</span><span>E</span><span>W</span><span>&nbsp;</span><span>I</span><span>T</span><span>&nbsp;</span><span>O</span><span>U</span><span>T</span><span>!</span>
                            </button>
                        </div>
                        <div className='flex flex-col gap-2 bg-slate-900 text-white drop-shadow-lg h-[35rem] w-[38rem]  p-6 rounded-2xl it items-center justify-center portfolio-item'  style={{backgroundImage: `url(${stjybcard})` , backgroundSize: `cover`}}>
                            <div className={'flex flex-row w-full justify-center gap-2'}>
                                <i><SiAdobephotoshop size='35' style={{fill: 'white'}}/></i>
                            </div>
                            <div className={'w-100 p-5'}>
                               Served as both 2020 and 2021's Yearbook Lead designer for St. Joseph High School - Saskatoon, I have created a cover page that emphasize the "making connections" by using orbs to represent each individuals in our school. That way, a message of "along the way, no matter where you are, we will still make connections between us" is conveyed.
                            </div>
                            <button className="button button--nina button--text-thick button--text-upper button--size-s bruh" onClick={() => {window.location.href = "https://imgbox.com/8edGfIx7"}}
                                    data-text=" St. Joseph HS 2021 Yearbook "><span>V</span><span>I</span><span>E</span><span>W</span><span>&nbsp;</span><span>I</span><span>T</span><span>&nbsp;</span><span>O</span><span>U</span><span>T</span><span>!</span>
                            </button>
                        </div>
                        <div className='flex flex-col gap-2 bg-slate-900 text-white drop-shadow-lg h-[35rem] w-[38rem]  p-6 rounded-2xl it items-center justify-center portfolio-item'  style={{backgroundImage: `url(${stjcard}` , backgroundSize: `cover`}}>
                            <div className={'flex flex-row w-full justify-center gap-2'}>
                                <i><SiAdobeillustrator size='35' style={{fill: 'white'}}/></i>
                                <i><SiAdobephotoshop size='35' style={{fill: 'white'}}/></i>
                            </div>
                            <div className={'w-100 p-5'}>
                                As part of the Graphic Arts 30 project, we are tasked to create a "Thank You" card for guest speakers that visits St. Joseph. With the emphasis on christianity and a little nod to the color scheme of the school, I created two Thank You Card that is simplistic and conveys "Thank You" to the guest speakers.
                            </div>
                            <button className="button button--nina button--text-thick button--text-upper button--size-s bruh" onClick={() => {window.location.href = "https://imgbox.com/A6K6xMFB"}}
                                    data-text=" St. Joseph HS - Card "><span>V</span><span>I</span><span>E</span><span>W</span><span>&nbsp;</span><span>I</span><span>T</span><span>&nbsp;</span><span>O</span><span>U</span><span>T</span><span>!</span>
                            </button>
                        </div>
                        <div className='flex flex-col gap-2 bg-slate-900 text-white drop-shadow-lg h-[35rem] w-[38rem]  p-6 rounded-2xl it items-center justify-center portfolio-item'  style={{backgroundImage: `url(${hokkaido})` , backgroundSize: `cover`}}>
                            <div className={'flex flex-row w-full justify-center gap-2'}>
                                <i><SiAdobephotoshop size='35' style={{fill: 'white'}}/></i>
                            </div>
                            <div className={'w-100 p-5'}>
                                As part of the Graphic Arts 30 project, we are tasked to create a fake travel advertisement brochure. For that reason, I created a mock travel advertisement for the island of Hokkaido in Japan that showcases their tourism activity for all-round season.
                            </div>
                            <button className="button button--nina button--text-thick button--text-upper button--size-s bruh" onClick={() => {window.location.href = "https://imgbox.com/P1kHt4fU"}}
                                    data-text="Mock Hokkaido Ad Brochure "><span>V</span><span>I</span><span>E</span><span>W</span><span>&nbsp;</span><span>I</span><span>T</span><span>&nbsp;</span><span>O</span><span>U</span><span>T</span><span>!</span>
                            </button>
                        </div>
                    </div>

                }

            </div>






            {/*MOBILE */}

            <div className='flex flex-col gap-2 bg-slate-900 m-5 text-white drop-shadow-lg p-5 rounded-l-3xl rounded-r-3xl justify-center items-center max-[1199px]:flex min-[1200px]:hidden ' id='intro' style={{backgroundImage: `url(${bgpattern3})`}}>
                <div className='flex items-center justify-center '>
                    <img src={portoLogo} alt='SpecialitiesAndSkills' width={'50%'}/>
                </div>

                <div className={'flex flex-col w-full items-center'}>
                    <h1 className={'text-md text-center'}>
                        For over three years in the creative and programming industry, I've been creating sophisicated web and general programming applications to the aesthetically pleasing graphic designs that both clients loved. <br></br><br></br> Let's check it out, shall we?
                    </h1>
                </div>

                <div className="flex flex-row flex-wrap gap-1 justify-center">
                    <button className="button button--nina button--text-thick button--text-upper button--size-m"
                            onClick={() => setPortfolio("PROGRAM")}
                            data-text="PROGRAMMING"><span>P</span><span>R</span><span>O</span><span>G</span><span>A</span><span>M</span><span>M</span><span>I</span><span>N</span><span>G</span>
                    </button>
                    <button className="button button--nina button--text-thick button--text-upper button--size-m"
                            onClick={() => setPortfolio("GRAPHIC")}
                            data-text="GRAPHIC ART"><span>G</span><span>R</span><span>A</span><span>P</span><span>H</span><span>I</span><span>C</span><span>&nbsp;</span><span>A</span><span>R</span><span>T</span>
                    </button>
                </div>

                {portfolio === "PROGRAM" &&

                    <div className="flex flex-row  w-full  flex-wrap gap-12 m-8 items-center justify-center">

                        <div className='flex flex-col gap-2 bg-slate-900 text-white drop-shadow-lg h-[63rem] w-[32rem] p-6 rounded-2xl it items-center justify-center portfolio-item'  style={{backgroundImage: `url(${broadbg})`}}>
                            <img src={broadlogo} alt='WhatISpecializeOn' width='50%'/>
                            <div className={'flex flex-row w-full justify-center gap-2'}>
                                <i><IoLogoJavascript size='35' style={{fill: 'white'}}/></i>
                                <i><IoLogoHtml5 size='35' style={{fill: 'white'}}/></i>
                                <i><IoLogoCss3 size='35' style={{fill: 'white'}}/></i>
                                <i><SiJquery size='35' style={{fill: 'white'}}/></i>
                            </div>
                            <div className={'w-100 p-1'}>
                                The Broadway Roastery Website served as one of our website redesign projects that my group made for the CWEB195 (UI/UX Fundamentals) course at Saskatchewan Polytechnic. <br/><br/>
                                The goal of the project is to heavily redesign one exisiting website and make it more user friendly and appealing to the general public. <br/><br/>
                                Due to the <a href='https://www.broadwayroastery.com/'><u>original website's</u></a> contents were lacking for us to serve as a basis, me and our group decided to remade it from the ground up. <br/><br/>
                                I have worked on the UI design aspect of it, as well as form validation on the contact page of it. It was a fun project, however, considering we learned how coffee business works along the way.
                            </div>
                            <button className="button button--nina button--text-thick button--text-upper button--size-s bruh" onClick={() => {window.location.href = "https://github.com/otakuweebster/BroadwayRoasteryRedesign"}}
                                    data-text=" Broadway Roastery "><span>V</span><span>I</span><span>E</span><span>W</span><span>&nbsp;</span><span>O</span><span>N</span><span>&nbsp;</span><span>G</span><span>I</span><span>T</span><span>H</span><span>U</span><span>B</span>
                            </button>
                        </div>
                        <div className='flex flex-col gap-2 bg-slate-900 text-white drop-shadow-lg h-[69rem] w-[32rem] p-6 rounded-2xl it items-center justify-center portfolio-item'  style={{backgroundImage: `url(${cadcammybg})`}}>
                            <img src={cadcammylgo} alt='WhatISpecializeOn' width='85%'/>
                            <div className={'flex flex-row w-full justify-center gap-2'}>
                                <i><FaJava size='35' style={{fill: 'white'}}/></i>
                            </div>
                            <div className={'w-100 p-1'}>
                                The Invasion of the Evil Mutant Cadcam Aliens is one of the assignments that me and my fellow student, Levi Krozser, has created for the COSC190 (Advanced Programming) at Saskatchewan Polytechnic. <br/><br/>
                                The idea of the entire assignment is to showcase how thread operations and JavaFX (Java's client application) works by incorporating it into a Space Invaders-like game.<br/><br/>
                                Me and my partner worked it in a way that is quite interesting and interactive by incorporating custom-designed characters and assets I have created.<br/><br/>
                                For that reason, I have fond good memories due to how complex thread operations work, in the same recreating the program to have a unique style and design that made it stand out from the rest.
                            </div>
                            <button className="button button--nina button--text-thick button--text-upper button--size-s bruh" onClick={() => {window.location.href = "https://github.com/otakuweebster/cad-cam-invaders"}}
                                    data-text="THE INVASION OF CADCAM ALIENS"><span>V</span><span>I</span><span>E</span><span>W</span><span>&nbsp;</span><span>O</span><span>N</span><span>&nbsp;</span><span>G</span><span>I</span><span>T</span><span>H</span><span>U</span><span>B</span>
                            </button>
                        </div>
                        <div className='flex flex-col gap-2 bg-slate-900 text-white drop-shadow-lg h-[69rem] w-[32rem] p-6 rounded-2xl it items-center justify-center portfolio-item'  style={{backgroundImage: `url(${thewatchbg})`}}>
                            <img src={thewatchlogo} alt='WhatISpecializeOn' width='50%'/>
                            <div className={'flex flex-row w-full justify-center gap-2'}>
                                <i><FaJava size='35' style={{fill: 'white'}}/></i>
                            </div>
                            <div className={'w-100 p-1'}>
                                theWatch is one of my individual final projects for the COSC195 (Android Programming) at Saskatchewan Polytechnic.<br/><br/>
                                The entire goal of the project is to showcase how different mobile operations, database calling, and codes to interact with different modules in Java in a way that we incorporate them in our style.<br/><br/>
                                The idea of theWatch is to act as a "community crime collector/aggregator" to report suspicious criminal activity in their area and alert residents of the neighborhood where the crime has been reported.<br/><br/>
                                In the end, I have created an application, albeit beta-like, that meets those requirements. Basically, it was a fun way how Java and Android Studio cooperates each other to create an app.
                            </div>
                            <button className="button button--nina button--text-thick button--text-upper button--size-s bruh" onClick={() => {window.location.href = "https://github.com/otakuweebster/The-Watch"}}
                                    data-text="theWatch"><span>V</span><span>I</span><span>E</span><span>W</span><span>&nbsp;</span><span>O</span><span>N</span><span>&nbsp;</span><span>G</span><span>I</span><span>T</span><span>H</span><span>U</span><span>B</span>
                            </button>
                        </div>
                    </div>

                }

                {portfolio === "GRAPHIC" &&

                    <div className="flex  w-full flex-row flex-wrap gap-12 m-8 items-center justify-center">

                        <div className='flex flex-col gap-2 bg-slate-900 text-white drop-shadow-lg h-[35rem] w-[32rem] p-6 rounded-2xl it items-center justify-center portfolio-item'  style={{backgroundImage: `url(${hrytsak})`}}>
                            <div className={'flex flex-row w-full justify-center gap-2'}>
                                <i><SiAdobephotoshop size='35' style={{fill: 'white'}}/></i>
                            </div>
                            <div className={'w-100 p-5'}>
                                Designed a woodgraining logo for a client to emphasizes on woodcrafting and little canadian elements on it. Using the client's old logo as a basis, I made improvements with it by incorporating multiple woodcrafting elements with a little hint of the client's idea and Canadian elements in it.
                            </div>
                            <button className="button button--nina button--text-thick button--text-upper button--size-s bruh" onClick={() => {window.location.href = "https://imgbox.com/zjvCbmFE"}}
                                    data-text=" D.S. HRYTSAK "><span>V</span><span>I</span><span>E</span><span>W</span><span>&nbsp;</span><span>I</span><span>T</span><span>&nbsp;</span><span>O</span><span>U</span><span>T</span><span>!</span>
                            </button>
                        </div>
                        <div className='flex flex-col gap-2 bg-slate-900 text-white drop-shadow-lg h-[39rem] w-[32rem] p-6 rounded-2xl it items-center justify-center portfolio-item'  style={{backgroundImage: `url(${stjybcard})`}}>
                            <div className={'flex flex-row w-full justify-center gap-2'}>
                                <i><SiAdobephotoshop size='35' style={{fill: 'white'}}/></i>
                            </div>
                            <div className={'w-100 p-5'}>
                                Served as both 2020 and 2021's Yearbook Lead designer for St. Joseph High School - Saskatoon, I have created a cover page that emphasize the "making connections" by using orbs to represent each individuals in our school. That way, a message of "along the way, no matter where you are, we will still make connections between us" is conveyed.
                            </div>
                            <button className="button button--nina button--text-thick button--text-upper button--size-s bruh" onClick={() => {window.location.href = "https://imgbox.com/8edGfIx7"}}
                                    data-text=" St. Joseph HS 2021 Yearbook "><span>V</span><span>I</span><span>E</span><span>W</span><span>&nbsp;</span><span>I</span><span>T</span><span>&nbsp;</span><span>O</span><span>U</span><span>T</span><span>!</span>
                            </button>
                        </div>
                        <div className='flex flex-col gap-2 bg-slate-900 text-white drop-shadow-lg h-[35rem] w-[32rem] p-6 rounded-2xl it items-center justify-center portfolio-item'  style={{backgroundImage: `url(${stjcard}`}}>
                            <div className={'flex flex-row w-full justify-center gap-2'}>
                                <i><SiAdobeillustrator size='35' style={{fill: 'white'}}/></i>
                                <i><SiAdobephotoshop size='35' style={{fill: 'white'}}/></i>
                            </div>
                            <div className={'w-100 p-5'}>
                                As part of the Graphic Arts 30 project, we are tasked to create a "Thank You" card for guest speakers that visits St. Joseph. With the emphasis on christianity and a little nod to the color scheme of the school, I created two Thank You Card that is simplistic and conveys "Thank You" to the guest speakers.
                            </div>
                            <button className="button button--nina button--text-thick button--text-upper button--size-s bruh" onClick={() => {window.location.href = "https://imgbox.com/A6K6xMFB"}}
                                    data-text=" St. Joseph HS - Card "><span>V</span><span>I</span><span>E</span><span>W</span><span>&nbsp;</span><span>I</span><span>T</span><span>&nbsp;</span><span>O</span><span>U</span><span>T</span><span>!</span>
                            </button>
                        </div>
                        <div className='flex flex-col gap-2 bg-slate-900 text-white drop-shadow-lg h-[35rem] w-[32rem] p-6 rounded-2xl it items-center justify-center portfolio-item'  style={{backgroundImage: `url(${hokkaido})`}}>
                            <div className={'flex flex-row w-full justify-center gap-2'}>
                                <i><SiAdobephotoshop size='35' style={{fill: 'white'}}/></i>
                            </div>
                            <div className={'w-100 p-5'}>
                                As part of the Graphic Arts 30 project, we are tasked to create a fake travel advertisement brochure. For that reason, I created a mock travel advertisement for the island of Hokkaido in Japan that showcases their tourism activity for all-round season.
                            </div>
                            <button className="button button--nina button--text-thick button--text-upper button--size-s bruh" onClick={() => {window.location.href = "https://imgbox.com/P1kHt4fU"}}
                                    data-text="Mock Hokkaido Ad Brochure "><span>V</span><span>I</span><span>E</span><span>W</span><span>&nbsp;</span><span>I</span><span>T</span><span>&nbsp;</span><span>O</span><span>U</span><span>T</span><span>!</span>
                            </button>
                        </div>
                    </div>

                }

            </div>
        </div>
    )
}

export default Portfolio