import React, {useEffect, useRef, useState} from 'react';
import NavBar from "../components/NavBar";
import FooterBar from "../components/FooterBar";
import bg2 from '../media/homepage/bg2.png';
import bg3 from '../media/homepage/bg3.png';
import bg4 from '../media/homepage/bg4.png';
import gabe from '../media/homepage/gabrielbeltran_logo.png'
import gabe2 from '../media/homepage/gabrielbeltran_logo_mb.png'
import frontend from '../media/homepage/frontend.png'
import artwork from '../media/homepage/artwork.png'
import {Button, Navbar} from "flowbite-react";
import {ParallaxLayer, Parallax} from "@react-spring/parallax";
import { useNavigate } from "react-router";

import totoro from '../media/revised/totoro.png'
import totoromb from '../media/revised/totoro_mb.png'
import totoro_layer2 from "../media/revised/totoro_layer2.png"
import totoro_moon from "../media/revised/totoro_moon.png"
import divbk from '../media/revised/div.png'
import divbk2 from '../media/revised/div2.png'
import divbkmb from '../media/revised/divmb.png'
import divbk2mb from '../media/revised/div2mb.png'

import totoro_moon_mb from '../media/revised/totoro_moon_mb.png'
import totoro_lay_2 from '../media/revised/totoro_layer2_mb.png'
import blk from '../media/revised/blk.png'
import blk2 from '../media/revised/blk2.png'
import { animated, useSpring } from "react-spring";
import {
    AiFillBook, AiFillClockCircle,
    AiFillFormatPainter,
    AiFillGithub,
    AiFillHtml5,
    AiFillLinkedin,
    AiOutlineTeam
} from "react-icons/ai";
import { IconContext } from "react-icons";
import logo from "../media/logo/gabrielb_logo.gif";

//LOGO
import gabelogo from '../media/NEW/RETRO_LOGO.png'
import sig2 from '../media/NEW/signiture2.png'

//SECTIONS
import AboutMe from "../components/AboutMe";
import SpecialSkills from "../components/SpecialSkills";
import Portfolio from "../components/Portfolio";
import Contact from "../components/Contact";
import signiture from "../media/NEW/signiture.png";

import { Link, animateScroll as scroll } from "react-scroll";


function LandingPage()
{
    const ref = useRef();
    const navigate = useNavigate();

    const [width, setWidth] = useState(window.innerWidth);
    const [curSkills, setSkills] = useState("technical");
    const [curJobSelect, setJob] = useState("dq");
    const [technicalSkill, setTechSkill] = useState("general")
    const isMobile = window.innerWidth <= 1200;

    useEffect( () => {
        window.addEventListener('resize', handleWindowSizeChange);

        window.scrollTo(0, 0)

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    function handleWindowSizeChange()
    {
        setWidth(window.innerWidth);
    }

    function handleCurSkills(skillsSelection)
    {
        setSkills(skillsSelection);
    }

    function handleJobSelection(job)
    {
        setJob(job)
    }


    return (
        <IconContext.Provider value={{ color: "white", size: "5em"}}>
            <div className="w-auto md:justify-center md:items-center" role="main" >
                <Parallax pages={isMobile ? 10 : 5.5} ref={ref}>
                    <ParallaxLayer offset={0} sticky={{start: 0, end: 8}} className='navybar'>
                        <div>
                            <Navbar
                                fluid={true}
                            >
                                <Navbar.Brand onClick={() => {
                                    if (isMobile)
                                    {
                                        ref.current.scrollTo(0)
                                    }
                                    else
                                    {
                                        ref.current.scrollTo(0)
                                    }
                                }}>
                                    <img
                                        src={logo}
                                        className="mr-3 h-6 sm:h-9"
                                        alt="Gabriel Beltran"
                                    />
                                </Navbar.Brand>
                                <Navbar.Toggle />
                                <Navbar.Collapse >
                                    <Navbar.Link onClick={() => {
                                        if (isMobile)
                                        {
                                            ref.current.scrollTo(1)
                                        }
                                        else
                                        {
                                            ref.current.scrollTo(1)
                                        }
                                    }}>
                                        .aboutMe()
                                    </Navbar.Link>
                                    <Navbar.Link onClick={() => {
                                        if (isMobile)
                                        {
                                            ref.current.scrollTo(2)
                                        }
                                        else
                                        {
                                            ref.current.scrollTo(2)
                                        }
                                    }}>
                                        .skillsAndExperiences()
                                    </Navbar.Link>
                                    <Navbar.Link onClick={() => {
                                        if (isMobile)
                                        {
                                            ref.current.scrollTo(4.5)
                                        }
                                        else
                                        {
                                            ref.current.scrollTo(3)
                                        }
                                    }}>
                                        .portfolio()
                                    </Navbar.Link>
                                    <Navbar.Link onClick={() => {
                                        if (isMobile)
                                        {
                                            ref.current.scrollTo(7)
                                        }
                                        else
                                        {
                                            ref.current.scrollTo(4)
                                        }

                                    }}>
                                        .contact()
                                    </Navbar.Link>
                                    <Navbar.Link href="https://www.linkedin.com/in/gabriel-audrey-beltran-4006b221b/">
                                        <IconContext.Provider value={{ color: "white", size: "2em"}}>
                                            <AiFillLinkedin/>
                                        </IconContext.Provider>
                                    </Navbar.Link>
                                    <Navbar.Link href="https://github.com/otakuweebster">
                                        <IconContext.Provider value={{ color: "white", size: "2em"}}>
                                            <AiFillGithub/>
                                        </IconContext.Provider>
                                    </Navbar.Link>

                                </Navbar.Collapse>
                            </Navbar>
                        </div>
                    </ParallaxLayer>

                    <ParallaxLayer offset={0} factor={1} speed={0.2}>
                        <img src={totoro_moon_mb} className="block md:hidden w-screen" alt="..."/>
                        <img src={totoro_moon} className="hidden md:block  w-screen" alt="..."/>
                    </ParallaxLayer>
                    <ParallaxLayer offset={0} factor={1} speed={0.8}>
                        <img src={totoro_layer2} className="hidden md:block  w-screen" alt="..."/>
                        <img src={totoro_lay_2} className="block md:hidden w-screen" alt="..."/>
                    </ParallaxLayer>
                    <ParallaxLayer offset={1} factor={8} speed={0.2} style={{backgroundColor: "#192841"}}>
                    </ParallaxLayer>
                    <ParallaxLayer offset={0} factor={8} speed={1.2} >
                        <img src={totoro} className="hidden md:block w-screen" alt="..."/>
                        <img src={totoromb} className="block md:hidden w-screen" alt="..."/>
                    </ParallaxLayer>

                    {/*OUR CONTENTS - NON MOBILE*/}
                    <ParallaxLayer offset={0} speed={1} factor={5} className='text h-screen' style={{backgroundImage: `url(${blk})`, backgroundSize: "contain" }}>
                        <div className='flex flex-col'>
                            <div className='flex h-screen items-center justify-center w-screen' id='intro'>
                                <img src={gabelogo} className='p-3' alt=''/>
                            </div>
                            <div className='flex h-screen items-center justify-center w-screen'>

                            </div>
                            <div id='aboutme'>
                                <AboutMe/>
                            </div>
                            <div id='specialskills'>
                                <SpecialSkills/>
                            </div>
                            <div id='portfolio'>
                                <Portfolio/>
                            </div>
                            <div id='contact'>
                                <Contact/>
                            </div>
                            <hr className="w-1/2 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"/>
                            <h1 className={'text-xl text-center py-3 text-white'}>
                                Made with love ♡
                            </h1>
                            <h1 className={'text-xl text-center text-white'}>
                                © Gabriel Beltran - April 2023
                            </h1>
                        </div>
                    </ParallaxLayer>
                </Parallax>
            </div>
        </IconContext.Provider>
    )
}

export default LandingPage;