import {ParallaxLayer, Parallax} from "@react-spring/parallax";
import NavBar from "../components/NavBar";
import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./../App.css"
import FooterBar from "../components/FooterBar";
import aboutmebg from "../media/aboutme/bg_aboutme.png";
import gabe2 from "../media/aboutme/gabrielbeltran_1.png";
import gabe3 from "../media/aboutme/gabrielbeltran_2.png";
import humble from '../media/aboutme/animation/the_humble_beginnigs.gif'
import newChap from '../media/aboutme/animation/the_new_chap.gif'
import arrow from '../media/aboutme/arrow.png'
import {
    AiFillGithub,
    AiFillLinkedin,
    AiFillMail,
    AiFillFormatPainter,
    AiFillHtml5,
    AiOutlineTeam,
    AiFillBook, AiFillClockCircle
} from "react-icons/ai";
import { IconContext } from "react-icons";
import {redirect} from "react-router-dom";
import bg4 from "../media/homepage/bg4.png";
import '../index.css'
import {Progress} from "flowbite-react";
import {DiCss3, DiJava} from "react-icons/di";
import {TbCSharp, TbListDetails} from "react-icons/tb";
import {SiNeo4J, SiOracle} from "react-icons/si";
import {SlSpeech, SlOrganization} from "react-icons/sl";
import {RiBrush4Fill} from "react-icons/ri";
import {MdOutlineReportProblem} from "react-icons/md";


export default function AboutMe()
{
    const [width, setWidth] = useState(window.innerWidth);
    const ref = useRef();
    const navigate = useNavigate();
    const currentKey = useLocation().pathname;
    const isMobile = window.innerWidth <= 768;

    useEffect( () => {
        window.addEventListener('resize', handleWindowSizeChange);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    function handleWindowSizeChange()
    {
        setWidth(window.innerWidth);
    }

    if (isMobile)
    {
        return (
            <IconContext.Provider value={{ color: "white", size: "5em"}}>
            <div className="h-full"  style={{backgroundImage: `url(${aboutmebg})`}}>
                <div className='h-full'>
                    <NavBar current={'about'}/>
                    <div id='leftSide1' className='p-10'>
                        <div><h1 className='text-white text-5xl py-2 text-center'><span style={{color: 'green'}}>></span>Woah, a page all about me!_</h1></div>
                        <div><h2 className='text-white text-center' >An in-depth analysis of who am I in both professional and personal level.</h2></div>
                        <div><h2 className='text-white text-center py-5' >EDITED AS OF 2023-01-14</h2></div>
                        <img src={gabe2} alt='Photo of Gabe Beltran'/>
                        <div>
                            <div><h1 className='text-white md:text-9xl text-4xl py-5'><span style={{color: 'green'}}>></span>Gabriel Beltran</h1></div>
                            <div><p className='text-white py-2'>FRONT-END PROGRAMMER AND GRAPHIC ART DESIGNER </p></div>
                            <div className='md:w-0.83'><span className='text-white'>Saskatoon-based front-end developer and graphic design artist dedicated to provide a product in two ways: "<i>efficiently and attractively</i>"</span></div>
                        </div>

                        <div className='border-t-2 p-5 mt-12'></div>
                        <div id='leftSide1' className='pt-5'>
                            <div className='flex flex-row flex-grow flex-wrap align-middle justify-center'>
                                <div className='p-5 justify-center align-middle'>
                                    <img src={humble} alt='Photo of Gabe Beltran'/>
                                </div>
                            </div>
                            <div id='rightSide1' className='p-10 lg:w-1/2 m:h-64 md:m-8' >
                                <div className='flex flex-col justify-center min-h-full w-full md:text-start'>
                                    <div><h1 className='text-white md:text-6xl text-4xl py-1'> <span style={{color: 'green'}}> ></span>From Humble Beginnings_</h1></div>
                                    <div><h2 className='text-white text-sm py-3' >(2014 - 2021)</h2></div>
                                    <div className='md:w-0.83'><span className='text-white'>I've been a graphic designer at heart since grade-school, long before the basic idea of web development ever crossed into my meager brain. All of this unhealthy obsession
        with graphic designing started off as a curiosity, until it eventually devolved into an addiction.
        The fact that a single program can convey ones imagination in such flexibility and ease is what grabbed my attention and thus, I embraced it like a cute child that unlocked
        my true self. Eventually, after immigrating to Canada, I eventually mastered the in's and out's of graphic design.</span></div>
                                    <div className='md:w-0.83 py-3'><span className='text-white'>That's just one part of the story, however...</span></div>
                                </div>
                            </div>
                            <div id='leftSide1' className='p-5'>
                                <div className='flex flex-row flex-grow flex-wrap align-middle justify-center'>
                                    <div className='p-5 justify-center align-middle'>
                                        <img src={arrow} alt='Photo of Gabe Beltran'/>
                                    </div>
                                </div>
                            </div>
                            <div id='leftSide1' className='p-5'>
                                <div className='flex flex-row flex-grow flex-wrap align-middle justify-center'>
                                    <div className='p-5 justify-center align-middle'>
                                        <img src={newChap} alt='Photo of Gabe Beltran'/>
                                    </div>
                                    <div id='rightSide1' className='p-10 lg:w-1/2 m:h-64 md:m-8' >
                                        <div className='flex flex-col justify-center min-h-full w-full md:text-start'>
                                            <div><h1 className='text-white md:text-6xl text-4xl py-2'><span style={{color: 'green'}}>></span>The big shift towards a new chapter_</h1></div>
                                            <div><h2 className='text-white text-sm py-3' >(2021 - 2022)</h2></div>
                                            <div className='md:w-0.83'><span className='text-white'>Fast-forward to 2021 and Saskatchewan Polytechnic's Computer Systems Technology has introduced me the second best thing that ever happend to mankind (or just to me), the
    principles of front-end framework. While the emphasis of the entire program is problem solving skills development (by using different languages such as Java,
    JavaScript, SQL, and others), the program has captured me even more when it introduced me in front-end development. By that point, there's no turning back.</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id='leftSide1' className='p-5'>
                            <div className='flex flex-row flex-grow flex-wrap align-middle justify-center'>
                                <div className='p-5 justify-center align-middle'>
                                    <img src={arrow} alt='Photo of Gabe Beltran'/>
                                </div>
                            </div>
                            </div>

                            <div id='leftSide1' className='p-5'>
                                <div className='flex flex-row flex-grow flex-wrap align-middle justify-center'>
                                    <div className='p-5 justify-center align-middle'>
                                        <img src={gabe3} alt='Photo of Gabe Beltran'/>
                                    </div>
                                    <div id='rightSide1' className='p-10 lg:w-1/2 m:h-64 md:m-8' >
                                        <div className='flex flex-col justify-center min-h-full w-full md:text-start'>
                                            <div className='flex flex-col justify-center min-h-full w-full md:text-start'>
                                                <div><h1 className='text-white md:text-6xl text-4xl py-2'><span style={{color: 'green'}}>></span>The Present_ </h1></div>
                                                <div className='md:w-0.83'><span className='text-white'>Since then, on each assignment, client projects, and side-projects, the combination of my love in graphic design and front-end development has furtured expressed my ideas
    into a whole new level. Whether I want to make a quick website on a copycat cookie clicker or make website layout that follows the design principles that one client
    is looking for his/her website, that imagination is just one coding away.
                                            </span></div>
                                                <div className='md:w-0.83 py-2'><span className='text-white'>And with a supportive team of friends backing me up throughout my adventure, front-end development has never been easier than ever!</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id='leftSide1' className='p-5'>
                                <div className='p-5'>
                                    <h1 className='text-white text-center md:text-4xl sm:text-7xl py-5'><span style={{color: 'green'}}>></span>Technical Skills</h1>
                                    <div className='flex flex-row flex-grow flex-wrap align-middle justify-center border-t-2'>
                                        <div id='rightSide1' className='p-10 m:h-64 md:m-8' >
                                            <div className='grid grid-cols-2 gap-10'>
                                                <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                    <div className='flex align-center justify-center'>
                                                        <AiFillFormatPainter/>
                                                    </div>
                                                    <h1 className='text-white text-center'>Web Design</h1>
                                                </div>
                                                <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                    <div className='flex align-center justify-center'>
                                                        <AiFillHtml5/>
                                                        <DiCss3/>
                                                        <AiFillHtml5/>
                                                    </div>
                                                    <h1 className='text-white text-center'>HTML/CSS/Javascript</h1>
                                                </div>
                                                <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                    <div className='flex align-center justify-center'>
                                                        <DiJava/>
                                                        <TbCSharp/>
                                                    </div>
                                                    <h1 className='text-white text-center'>Java/C/C#</h1>
                                                </div>
                                                <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                    <div className='flex align-center justify-center'>
                                                        <SiNeo4J/>
                                                        <SiOracle/>
                                                    </div>
                                                    <h1 className='text-white text-center'>SQL (Neo4J/Oracle SQL)</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id='leftSide1' className='p-5'>
                                <div className='p-5'>
                                    <h1 className='text-white text-center md:text-4xl sm:text-7xl py-5'><span style={{color: 'green'}}>></span>Personal Skills</h1>
                                    <div className='flex flex-row flex-grow flex-wrap align-middle justify-center border-t-2'>
                                        <div id='rightSide1' className='p-10 m:h-64 md:m-8' >
                                            <div className='grid grid-cols-4 gap-8 align-center justify-center'>
                                                <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                    <div className='flex align-center justify-center'>
                                                        <SlSpeech/>
                                                    </div>
                                                    <h1 className='text-white text-center'>Communication</h1>
                                                </div>
                                                <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                    <div className='flex align-center justify-center'>
                                                        <AiOutlineTeam/>
                                                    </div>
                                                    <h1 className='text-white text-center'>Teamwork</h1>
                                                </div>
                                                <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                    <div className='flex align-center justify-center'>
                                                        <SlOrganization/>
                                                    </div>
                                                    <h1 className='text-white text-center'>Organization</h1>
                                                </div>
                                                <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                    <div className='flex align-center justify-center'>
                                                        <RiBrush4Fill/>
                                                    </div>
                                                    <h1 className='text-white text-center'>Creativity</h1>
                                                </div>
                                                <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                    <div className='flex align-center justify-center'>
                                                        <MdOutlineReportProblem/>
                                                    </div>
                                                    <h1 className='text-white text-center'>Problem-solving</h1>
                                                </div>
                                                <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                    <div className='flex align-center justify-center'>
                                                        <AiFillBook/>
                                                    </div>
                                                    <h1 className='text-white text-center'>Eager-to-learn</h1>
                                                </div>
                                                <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                    <div className='flex align-center justify-center'>
                                                        <AiFillClockCircle/>
                                                    </div>
                                                    <h1 className='text-white text-center'>Time-management</h1>
                                                </div>
                                                <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                    <div className='flex align-center justify-center'>
                                                        <TbListDetails/>
                                                    </div>
                                                    <h1 className='text-white text-center'>Attention to Detail</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div id='leftSide1' className='p-5'>
                                <h1 className='text-white text-center md:text-4xl sm:text-7xl py-5'><span style={{color: 'green'}}>></span>Interested to learn more? Follow my adventures or contact me below!</h1>
                                <div className='flex flex-row flex-grow flex-wrap align-middle justify-center border-t-2'>
                                    <div id='rightSide1' className='p-10 m:h-64 md:m-8' >
                                        <div className='flex flex-col justify-center min-h-full w-full md:text-start'>
                                            <div>
                                                <div className='flex align-middle justify-center w-10 '>

                                                    <div className='px-10'>
                                                        <a href='https://www.linkedin.com/in/gabriel-audrey-beltran-4006b221b/'>
                                                            <AiFillLinkedin/>
                                                        </a>
                                                    </div>
                                                    <div  className='px-10'>
                                                        <a href='https://github.com/otakuweebster'>
                                                            <AiFillGithub/>
                                                        </a>
                                                    </div>
                                                    <div  className='px-10'>
                                                        <a href='/contact'>
                                                            <AiFillMail/>
                                                        </a>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>

                </div>
                <FooterBar/>
            </div>
            </IconContext.Provider>
        )
    }

    else {
        return (
            <IconContext.Provider value={{ color: "white", size: "3em"}}>
                <div className='h-screen bg-gray-800'>
                    <div className='invisible md:visible'>
                        <Parallax pages={2.15} ref={ref}>
                            <ParallaxLayer offset={0} sticky={{start: 0, end: 1}} className='navybar'>
                                <NavBar current={'about'}/>
                            </ParallaxLayer>
                            <ParallaxLayer offset={0} sticky={{start: 2.2, end: 2.2}} className='navybar'>
                                <FooterBar/>
                            </ParallaxLayer>
                            <ParallaxLayer offset={0} factor={3} speed={1} style={{
                                backgroundImage: `url(${aboutmebg})`}}>
                            </ParallaxLayer>
                            <ParallaxLayer offset={0.2} speed={2} className='text' style={{pointerEvents: "visible"}}>
                                <div className='flex flex-row flex-grow flex-wrap align-middle justify-center'>
                                    <div id='leftSide1' className='p-10'>
                                        <div><h1 className='text-white md:text-7xl text-4xl py-2 text-center'><span style={{color: 'green'}}>></span>Woah, a page all about me!_</h1></div>
                                        <div><h2 className='text-white text-center' >An in-depth analysis of who am I in both professional and personal level.</h2></div>
                                        <div><h2 className='text-white text-center py-5' >EDITED AS OF 2023-01-14</h2></div>
                                    </div>
                                    <div className='flex flex-row flex-grow flex-wrap'>
                                        <div id='leftSide1' className='p-11 lg:w-1/2 justify-center align-middle'>
                                            <img src={gabe2} alt='Photo of Gabe Beltran'/>
                                        </div>

                                        <div id='rightSide1' className='p-10 lg:w-1/2 m:h-64' >
                                            <div className='flex flex-col justify-center min-h-full w-full md:text-start'>
                                                <div><h1 className='text-white md:text-9xl text-4xl py-5'><span style={{color: 'green'}}>></span>Gabriel Beltran</h1></div>
                                                <div><p className='text-white py-2'>FRONT-END PROGRAMMER AND GRAPHIC ART DESIGNER </p></div>
                                                <div className='md:w-0.83'><span className='text-white'>Saskatoon-based front-end developer and graphic design artist dedicated to provide a product in two ways: "<i>efficiently and attractively</i>"</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-row flex-grow flex-wrap align-middle justify-center py-10'>
                                    <div id='leftSide1' className='p-5'>
                                        <div className='flex flex-row flex-grow flex-wrap align-middle justify-center'>
                                            <div className='p-5 justify-center align-middle'>
                                                <img src={humble} alt='Photo of Gabe Beltran'/>
                                            </div>
                                            <div id='rightSide1' className='p-10 lg:w-1/2 m:h-64 md:m-8' >
                                                <div className='flex flex-col justify-center min-h-full w-full md:text-start'>
                                                    <div><h1 className='text-white md:text-6xl text-4xl py-1'> <span style={{color: 'green'}}> ></span>From Humble Beginnings_</h1></div>
                                                    <div><h2 className='text-white text-sm py-3' >(2014 - 2021)</h2></div>
                                                    <div className='md:w-0.83'><span className='text-white'>I've been a graphic designer at heart since grade-school, long before the basic idea of web development ever crossed into my meager brain. All of this unhealthy obsession
        with graphic designing started off as a curiosity, until it eventually devolved into an addiction.
        The fact that a single program can convey ones imagination in such flexibility and ease is what grabbed my attention and thus, I embraced it like a cute child that unlocked
        my true self. Eventually, after immigrating to Canada, I eventually mastered the in's and out's of graphic design.</span></div>
                                                    <div className='md:w-0.83 py-3'><span className='text-white'>That's just one part of the story, however...</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id='leftSide1' className='p-5'>
                                        <div className='flex flex-row flex-grow flex-wrap align-middle justify-center'>
                                            <div className='p-5 justify-center align-middle'>
                                                <img src={arrow} alt='Photo of Gabe Beltran'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div id='leftSide1' className='p-5'>
                                        <div className='flex flex-row flex-grow flex-wrap align-middle justify-center'>
                                            <div className='p-5 justify-center align-middle'>
                                                <img src={newChap} alt='Photo of Gabe Beltran'/>
                                            </div>
                                            <div id='rightSide1' className='p-10 lg:w-1/2 m:h-64 md:m-8' >
                                                <div className='flex flex-col justify-center min-h-full w-full md:text-start'>
                                                    <div><h1 className='text-white md:text-6xl text-4xl py-2'><span style={{color: 'green'}}>></span>The big shift towards a new chapter_</h1></div>
                                                    <div><h2 className='text-white text-sm py-3' >(2021 - 2022)</h2></div>
                                                    <div className='md:w-0.83'><span className='text-white'>Fast-forward to 2021 and Saskatchewan Polytechnic's Computer Systems Technology has introduced me the second best thing that ever happend to mankind (or just to me), the
    principles of front-end framework. While the emphasis of the entire program is problem solving skills development (by using different languages such as Java,
    JavaScript, SQL, and others), the program has captured me even more when it introduced me in front-end development. By that point, there's no turning back.</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id='leftSide1' className='p-5'>
                                        <div className='flex flex-row flex-grow flex-wrap align-middle justify-center'>
                                            <div className='p-5 justify-center align-middle'>
                                                <img src={arrow} alt='Photo of Gabe Beltran'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div id='leftSide1' className='p-5'>
                                        <div className='flex flex-row flex-grow flex-wrap align-middle justify-center'>
                                            <div className='p-5 justify-center align-middle'>
                                                <img src={gabe3} alt='Photo of Gabe Beltran'/>
                                            </div>
                                            <div id='rightSide1' className='p-10 lg:w-1/2 m:h-64 md:m-8' >
                                                <div className='flex flex-col justify-center min-h-full w-full md:text-start'>
                                                    <div className='flex flex-col justify-center min-h-full w-full md:text-start'>
                                                        <div><h1 className='text-white md:text-6xl text-4xl py-2'><span style={{color: 'green'}}>></span>The Present_ </h1></div>
                                                        <div className='md:w-0.83'><span className='text-white'>Since then, on each assignment, client projects, and side-projects, the combination of my love in graphic design and front-end development has furtured expressed my ideas
    into a whole new level. Whether I want to make a quick website on a copycat cookie clicker or make website layout that follows the design principles that one client
    is looking for his/her website, that imagination is just one coding away.
                                            </span></div>
                                                        <div className='md:w-0.83 py-2'><span className='text-white'>And with a supportive team of friends backing me up throughout my adventure, front-end development has never been easier than ever!</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id='leftSide1' className='p-5 w-auto flex flex-row max-md:flex-wrap'>
                                        <div className='p-5'>
                                            <h1 className='text-white text-center md:text-4xl sm:text-7xl py-5'><span style={{color: 'green'}}>></span>Technical Skills</h1>
                                            <div className='flex flex-row flex-grow flex-wrap align-middle justify-center border-t-2'>
                                                <div id='rightSide1' className='p-10 m:h-64 md:m-8' >
                                                    <div className='grid grid-cols-4 gap-8'>
                                                        <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                            <div className='flex align-center justify-center'>
                                                                <AiFillFormatPainter/>
                                                            </div>
                                                            <h1 className='text-white text-center'>Web Design</h1>
                                                        </div>
                                                        <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                            <div className='flex align-center justify-center'>
                                                                <AiFillHtml5/>
                                                                <DiCss3/>
                                                                <AiFillHtml5/>
                                                            </div>
                                                            <h1 className='text-white text-center'>HTML/CSS/Javascript</h1>
                                                        </div>
                                                        <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                            <div className='flex align-center justify-center'>
                                                                <DiJava/>
                                                                <TbCSharp/>
                                                            </div>
                                                            <h1 className='text-white text-center'>Java/C/C#</h1>
                                                        </div>
                                                        <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                            <div className='flex align-center justify-center'>
                                                                <SiNeo4J/>
                                                                <SiOracle/>
                                                            </div>
                                                            <h1 className='text-white text-center'>SQL (Neo4J/Oracle SQL)</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='p-5'>
                                            <h1 className='text-white text-center md:text-4xl sm:text-7xl py-5'><span style={{color: 'green'}}>></span>Personal Skills</h1>
                                            <div className='flex flex-row flex-grow flex-wrap align-middle justify-center border-t-2'>
                                                <div id='rightSide1' className='p-10 m:h-64 md:m-8' >
                                                    <div className='grid grid-cols-4 gap-8 align-center justify-center'>
                                                        <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                            <div className='flex align-center justify-center'>
                                                                <SlSpeech/>
                                                            </div>
                                                            <h1 className='text-white text-center'>Communication</h1>
                                                        </div>
                                                        <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                            <div className='flex align-center justify-center'>
                                                                <AiOutlineTeam/>
                                                            </div>
                                                            <h1 className='text-white text-center'>Teamwork</h1>
                                                        </div>
                                                        <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                            <div className='flex align-center justify-center'>
                                                                <SlOrganization/>
                                                            </div>
                                                            <h1 className='text-white text-center'>Organization</h1>
                                                        </div>
                                                        <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                            <div className='flex align-center justify-center'>
                                                                <RiBrush4Fill/>
                                                            </div>
                                                            <h1 className='text-white text-center'>Creativity</h1>
                                                        </div>
                                                        <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                            <div className='flex align-center justify-center'>
                                                                <MdOutlineReportProblem/>
                                                            </div>
                                                            <h1 className='text-white text-center'>Problem-solving</h1>
                                                        </div>
                                                        <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                            <div className='flex align-center justify-center'>
                                                                <AiFillBook/>
                                                            </div>
                                                            <h1 className='text-white text-center'>Eager-to-learn</h1>
                                                        </div>
                                                        <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                            <div className='flex align-center justify-center'>
                                                                <AiFillClockCircle/>
                                                            </div>
                                                            <h1 className='text-white text-center'>Time-management</h1>
                                                        </div>
                                                        <div className='flex flex-col flex-grow flex-wrap align-center justify-center'>
                                                            <div className='flex align-center justify-center'>
                                                                <TbListDetails/>
                                                            </div>
                                                            <h1 className='text-white text-center'>Attention to Detail</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div id='leftSide1' className='p-5 w-auto'>
                                        <h1 className='text-white text-center md:text-4xl sm:text-7xl py-5'><span style={{color: 'green'}}>></span>Interested to learn more? Contact me below!</h1>
                                        <div className='flex flex-row flex-grow flex-wrap align-middle justify-center border-t-2'>
                                            <div id='rightSide1' className='p-10 m:h-64 md:m-8' >
                                                <div className='flex flex-col justify-center min-h-full w-full md:text-start'>
                                                    <div>
                                                        <div className='flex align-middle justify-center w-10 '>

                                                            <div className='px-10'>
                                                                <a href='https://www.linkedin.com/in/gabriel-audrey-beltran-4006b221b/'>
                                                                    <AiFillLinkedin/>
                                                                </a>
                                                            </div>
                                                            <div  className='px-10'>
                                                                <a href='https://github.com/otakuweebster'>
                                                                    <AiFillGithub/>
                                                                </a>
                                                            </div>
                                                            <div  className='px-10'>
                                                                <a href='/contact'>
                                                                    <AiFillMail/>
                                                                </a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ParallaxLayer>
                        </Parallax>
                    </div>
                </div>
            </IconContext.Provider>
        )
    }





}