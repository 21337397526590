import './App.css';
import { Route, Routes } from 'react-router-dom';
import LandingPage from './routes/LandingPage';
import AboutMe from './routes/AboutMe';
import ScrollToTop from "./ScrollToTop";
import "react-responsive-carousel/lib/styles/carousel.min.css";


function App() {

  return (
      <Routes>
        <Route path='/' element={<LandingPage/>}/>
          <Route path='/about_me' element={<AboutMe/>}/>
      </Routes>
  )

}

export default App;
