import React from 'react';
import logo from '../media/logo/gabrielb_logo.gif'
import {Button, Navbar} from "flowbite-react";


const NavBar = (props) => {
    const currentPage = props.current;

    return (
        <div>
            <Navbar
                fluid={true}
            >
                <Navbar.Brand href="/">
                    <img
                        src={logo}
                        className="mr-3 h-6 sm:h-9"
                        alt="Gabriel Beltran"
                    />
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse >
                        <Navbar.Link href="/#">
                            .aboutMe()
                        </Navbar.Link>
                        <Navbar.Link href="/#/about_me">
                            .experience()
                        </Navbar.Link>
                        <Navbar.Link href="/#/portfolio">
                            .portfolio()
                        </Navbar.Link>
                        <Navbar.Link href="/#/contact">
                            .contact()
                        </Navbar.Link>
                </Navbar.Collapse>
            </Navbar>
        </div>

)
};

export default NavBar;