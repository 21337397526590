import React from 'react';
import NavBar from "./NavBar";
import {Footer} from "flowbite-react";

const FooterBar = () => {
    return (
        <Footer container={true} className=''>
            <div className="w-full text-center">
                <Footer.Divider />
                <Footer.Copyright
                    href="#"
                    by="Gabriel Beltran"
                    year={2023}
                />
            </div>
        </Footer>

    );
};

export default FooterBar;