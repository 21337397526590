import React, {useEffect, useRef, useState} from 'react';
import gabelogo from "../media/NEW/RETRO_LOGO.png";
import gabehimself from "../media/NEW/WIDESCREEN_GABE.png";
import gabetall from "../media/NEW/TALL_GABE.png";
import aboutmelogo from '../media/NEW/ABOUT_ME.png'
import signiture from '../media/NEW/signiture.png'
import bgpattern from '../media/NEW/90s_pattern.png'


function AboutMe()
{
    return (

        <div id='aboutme'>
            <div className='flex flex-row gap-2 bg-slate-900 md:m-20 text-white shadow-2xl p-0 rounded-l-3xl rounded-r-3xl justify-center items-center max-[1199px]:hidden min-[1200px]:flex' id='intro'
                 style={{backgroundImage: `url(${bgpattern})`}}>
                <div className='w-1/2'>
                    <img src={gabehimself} className='rounded-l-3xl  pr-4' alt=''/>
                </div>
                <div className='w-1/2 p-6 flex flex-col items-center text-center text-md'>
                    <img src={aboutmelogo} className='w-auto' alt=''/>
                    <p className='p-3'>My name's Gabriel Beltran. Based in the Philippines but currently residing in Saskatchewan, Canada, I love crafting graphic designs and websites tailored to my or the client's interest.
                        <br></br>
                        <br></br>
                        I do believe that graphic arts and web designing should be both visually appealing, accessible for any people, and functional while still retaining the requirements of the client. Whether the product looks good in the end or has served the purpose of presenting the client's brand correctly, as long it appeals to a broader audience, then the product I have created has completed its mission: Make it clear but visually appealing.
                        <br></br>
                        <br></br>
                        Therefore, I am ready and committed to acquiring new knowledge as the hypothetical career path awaits me, and I mean it. And that's how I work in a creative world, learn, apply, and reap the benefits. It is challenging at first but rewarding in the end.
                        <br></br>
                        <br></br>
                        When I am not around coding or glued in front of my computer and graphic tablet, you will usually see me roaming around biking, spending time with parents and friends, or playing various games (Not that I am a huge gamer, though).
                        <br></br>
                        <br></br>
                        I look forward to working with you and bringing your creative idea to life!
                    </p>
                    <img src={signiture} className='w-auto mt-5' alt=''/>
                </div>
            </div>

            <div className='flex flex-col gap-2 bg-slate-900 m-5 text-white drop-shadow-lg pb-5 rounded-l-3xl rounded-r-3xl justify-center items-center max-[1199px]:flex min-[1200px]:hidden' id='intro' style={{backgroundImage: `url(${bgpattern})`}}>
                <div className=''>
                    <img src={gabetall} className='rounded-t-3xl' alt=''/>
                </div>
                <div className='m-10 flex flex-col items-center text-center'>
                    <img src={aboutmelogo} className='w-auto' alt=''/>
                    <p>My name's Gabriel Beltran. Based in the Philippines but currently residing in Saskatchewan, Canada, I love crafting graphic designs and websites tailored to my or the client's interest.
                        <br></br>
                        <br></br>
                        I do believe that graphic arts and web designing should be both visually appealing, accessible for any people, and functional while still retaining the requirements of the client. Whether the product looks good in the end or has served the purpose of presenting the client's brand correctly, as long it appeals to a broader audience, then the product I have created has completed its mission: Make it clear but visually appealing.
                        <br></br>
                        <br></br>
                        Therefore, I am ready and committed to acquiring new knowledge as the hypothetical career path awaits me, and I mean it. And that's how I work in a creative world, learn, apply, and reap the benefits. It is challenging at first but rewarding in the end.
                        <br></br>
                        <br></br>
                        When I am not around coding or glued in front of my computer and graphic tablet, you will usually see me roaming around biking, spending time with parents and friends, or playing various games (Not that I am a huge gamer, though).
                        <br></br>
                        <br></br>
                        I look forward to working with you and bringing your creative idea to life!
                    </p>
                    <img src={signiture} className='w-auto mt-5 ' alt=''/>
                </div>
            </div>
        </div>
    )

}



export default AboutMe